import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'abastecimento',
    loadChildren: () =>
      import('./abastecimento/abastecimento.module').then(
        (m) => m.AbastecimentoPageModule
      ),
  },
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'splash',
    loadChildren: () =>
      import('./splash/splash.module').then((m) => m.SplashPageModule),
  },
  {
    path: 'principal',
    loadChildren: () =>
      import('./principal/principal.module').then((m) => m.PrincipalPageModule),
  },
  {
    path: 'esqueci',
    loadChildren: () =>
      import('./esqueci/esqueci.module').then((m) => m.EsqueciPageModule),
  },
  {
    path: 'clientes',
    loadChildren: () =>
      import('./clientes/clientes.module').then((m) => m.ClientesPageModule),
  },
  {
    path: 'viewveiculo',
    loadChildren: () =>
      import('./viewveiculo/viewveiculo.module').then(
        (m) => m.ViewveiculoPageModule
      ),
  },
  {
    path: 'google',
    loadChildren: () =>
      import('./google/google.module').then((m) => m.GooglePageModule),
  },
  {
    path: 'novo-relatorio',
    loadChildren: () =>
      import('./novo-relatorio/novo-relatorio.module').then(
        (m) => m.NovoRelatorioPageModule
      ),
  },
  {
    path: 'nova-consulta',
    loadChildren: () =>
      import('./nova-consulta/nova-consulta.module').then(
        (m) => m.NovaConsultaPageModule
      ),
  },
  {
    path: 'test-dev',
    loadChildren: () =>
      import('./test-dev/test-dev.module').then((m) => m.TesteDevPageModule),
  },
  {
    path: 'conta',
    loadChildren: () =>
      import('./conta/conta.module').then((m) => m.ContaPageModule),
  },
  {
    path: 'criar-conta',
    loadChildren: () =>
      import('./criar-conta/criar-conta.module').then(
        (m) => m.CriarContaPageModule
      ),
  },
  {
    path: 'banners',
    loadChildren: () =>
      import('./banners/banners.module').then((m) => m.BannersPageModule),
  },
  {
    path: 'rate-instalador',
    loadChildren: () =>
      import('./rate-instalador/rate-instalador.module').then(
        (m) => m.RateInstaladorPageModule
      ),
  },
  {
    path: 'lgpd',
    loadChildren: () =>
      import('./lgpd/lgpd.module').then((m) => m.LgpdPageModule),
  },
  {
    path: 'termos-pre-acesso',
    loadChildren: () =>
      import('./termos-pre-acesso/termos-pre-acesso.module').then(
        (m) => m.TermosPreAcessoPageModule
      ),
  },
  {
    path: 'shortcuts',
    loadChildren: () =>
      import('./shortcuts/shortcuts.module').then((m) => m.ShortcutsPageModule),
  },
  {
    path: 'faturas-sga',
    loadChildren: () =>
      import('./faturas-sga/faturas-sga.module').then(
        (m) => m.FaturasSgaPageModule
      ),
  },
  {
    path: 'veiculo-parametros',
    loadChildren: () =>
      import('./veiculo-parametros/veiculo-parametros.module').then(
        (m) => m.VeiculoParametrosPageModule
      ),
  },
  {
    path: 'alertas',
    loadChildren: () =>
      import('./alertas/alertas.module').then((m) => m.AlertasPageModule),
  },
  {
    path: 'contratos',
    loadChildren: () =>
      import('./contratos/contratos.module').then((m) => m.ContratosPageModule),
  },
  {
    path: 'modal-hrp',
    loadChildren: () =>
      import('./modal-hrp/modal-hrp.module').then((m) => m.ModalHrpPageModule),
  },
  {
    path: 'modal-sga',
    loadChildren: () =>
      import('./modal-sga/modal-sga.module').then((m) => m.ModalSgaPageModule),
  },
  {
    path: 'modal-cliente',
    loadChildren: () =>
      import('./modal-cliente/modal-cliente.module').then(
        (m) => m.ModalClientePageModule
      ),
  },
  {
    path: 'faturas',
    loadChildren: () =>
      import('./faturas/faturas.module').then((m) => m.FaturasPageModule),
  },
  {
    path: 'faturamento-integracao',
    loadChildren: () =>
      import('./faturamento-integracao/faturamento-integracao.module').then(
        (m) => m.FaturamentoIntegracaoPageModule
      ),
  },
  {
    path: 'modal-cartao',
    loadChildren: () =>
      import('./modal-cartao/modal-cartao.module').then(
        (m) => m.ModalCartaoPageModule
      ),
  },
  {
    path: 'faturamento-logs',
    loadChildren: () =>
      import('./faturamento-logs/faturamento-logs.module').then(
        (m) => m.FaturamentoLogsPageModule
      ),
  },
  {
    path: 'consulta',
    loadChildren: () =>
      import('./consulta/consulta.module').then((m) => m.ConsultaPageModule),
  },
  {
    path: 'filtro',
    loadChildren: () =>
      import('./filtro/filtro.module').then((m) => m.FiltroPageModule),
  },
  {
    path: 'relatorio',
    loadChildren: () =>
      import('./relatorio/relatorio.module').then((m) => m.RelatorioPageModule),
  },
  {
    path: 'arquivos-list',
    loadChildren: () =>
      import('./arquivos-list/arquivos-list.module').then(
        (m) => m.ArquivosListPageModule
      ),
  },
  {
    path: 'atendimentos',
    loadChildren: () =>
      import('./atendimentos/atendimentos.module').then(
        (m) => m.AtendimentosPageModule
      ),
  },
  {
    path: 'atendimentos-chat',
    loadChildren: () =>
      import('./atendimentos-chat/atendimentos-chat.module').then(
        (m) => m.AtendimentosChatPageModule
      ),
  },
  {
    path: 'custos',
    loadChildren: () =>
      import('./custos/custos.module').then((m) => m.CustosPageModule),
  },
  {
    path: 'adicionais',
    loadChildren: () =>
      import('./adicionais/adicionais.module').then(
        (m) => m.AdicionaisPageModule
      ),
  },
  {
    path: 'indicacao',
    loadChildren: () =>
      import('./indicacao/indicacao.module').then((m) => m.IndicacaoPageModule),
  },
  {
    path: 'empresa',
    loadChildren: () =>
      import('./empresa/empresa.module').then((m) => m.EmpresaPageModule),
  },
  {
    path: 'shopping',
    loadChildren: () =>
      import('./shopping/shopping.module').then((m) => m.ShoppingPageModule),
  },
  {
    path: 'product-detail',
    loadChildren: () =>
      import('./product-detail/product-detail.module').then(
        (m) => m.ProductDetailPageModule
      ),
  },
  {
    path: 'product-exchange-form',
    loadChildren: () =>
      import('./product-exchange-form/product-exchange-form.module').then(
        (m) => m.ProductExchangeFormPageModule
      ),
  },
  {
    path: 'exchange-history',
    loadChildren: () =>
      import('./exchange-history/exchange-history.module').then(
        (m) => m.ExchangeHistoryPageModule
      ),
  },
  {
    path: 'saude',
    loadChildren: () =>
      import('./saude/saude.module').then((m) => m.SaudePageModule),
  },
  {
    path: 'cartao-associado',
    loadChildren: () =>
      import('./cartao-associado/cartao-associado.module').then(
        (m) => m.CartaoAssociadoPageModule
      ),
  },
  {
    path: 'atendimentos24',
    loadChildren: () =>
      import('./atendimentos24/atendimentos24.module').then(
        (m) => m.Atendimentos24PageModule
      ),
  },
  {
    path: 'termos',
    loadChildren: () =>
      import('./termos/termos.module').then((m) => m.TermosPageModule),
  },
  {
    path: 'eventos',
    loadChildren: () =>
      import('./eventos/eventos.module').then((m) => m.EventosPageModule),
  },
  {
    path: 'alterar',
    loadChildren: () =>
      import('./alterar/alterar.module').then((m) => m.AlterarPageModule),
  },
  {
    path: 'configuracoes',
    loadChildren: () =>
      import('./configuracoes/configuracoes.module').then(
        (m) => m.ConfiguracoesPageModule
      ),
  },
  {
    path: 'parceiros',
    loadChildren: () =>
      import('./parceiros/parceiros.module').then((m) => m.ParceirosPageModule),
  },
  {
    path: 'informativos',
    loadChildren: () =>
      import('./informativos/informativos.module').then(
        (m) => m.InformativosPageModule
      ),
  },
  {
    path: 'rotas',
    loadChildren: () =>
      import('./rotas/rotas.module').then((m) => m.RotasPageModule),
  },
  {
    path: 'novo-veiculo',
    loadChildren: () =>
      import('./novo-veiculo/novo-veiculo.module').then(
        (m) => m.NovoVeiculoPageModule
      ),
  },
  {
    path: 'comandos',
    loadChildren: () =>
      import('./comandos/comandos.module').then((m) => m.ComandosPageModule),
  },
  {
    path: 'vistoria',
    loadChildren: () =>
      import('./vistoria/vistoria.module').then((m) => m.VistoriaPageModule),
  },
  {
    path: 'vistoria-sga',
    loadChildren: () =>
      import('./vistoria-sga/vistoria-sga.module').then(
        (m) => m.VistoriaSgaPageModule
      ),
  },
  {
    path: 'veiculo-listar',
    loadChildren: () =>
      import('./veiculo-listar/veiculo-listar.module').then(
        (m) => m.VeiculoListarPageModule
      ),
  },
  {
    path: 'dependente-listar',
    loadChildren: () =>
      import('./dependente-listar/dependente-listar.module').then(
        (m) => m.DependenteListarPageModule
      ),
  },
  {
    path: 'dependentes',
    loadChildren: () =>
      import('./dependentes/dependentes.module').then(
        (m) => m.DependentesPageModule
      ),
  },
  {
    path: 'faturamento',
    loadChildren: () =>
      import('./faturamento/faturamento.module').then(
        (m) => m.FaturamentoPageModule
      ),
  },
  {
    path: 'intro',
    loadChildren: () =>
      import('./intro/intro.module').then((m) => m.IntroPageModule),
  },
  {
    path: 'locais',
    loadChildren: () =>
      import('./locais/locais.module').then((m) => m.LocaisPageModule),
  },
  {
    path: 'local',
    loadChildren: () =>
      import('./local/local.module').then((m) => m.LocalPageModule),
  },
  {
    path: 'tutoriais',
    loadChildren: () =>
      import('./tutoriais/tutoriais.module').then((m) => m.TutoriaisPageModule),
  },
  {
    path: 'page404',
    loadChildren: () =>
      import('./page404/page404.module').then((m) => m.Page404PageModule),
  },
  { path: '**', redirectTo: 'page404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
