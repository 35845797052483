import { Component } from '@angular/core';
import { UsuarioService } from 'src/providers/usuario-service';
import { UtilService } from 'src/providers/util-service';
import { AlertController, Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { setCssColors } from 'src/app/colors';
import { environment } from '../../environment';
import { register } from 'swiper/element/bundle';
import { OneSignal } from 'onesignal-ngx';

const versaoApp = environment.versaoAtual;

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  aparelho: string;
  plataforma: string;
  imei: string;

  constructor(
    public platform: Platform,
    public util: UtilService,
    public device: Device,
    public usuario: UsuarioService,
    public user: UsuarioService,
    private alertCtrl: AlertController,
    private oneSignal: OneSignal,
  ) {
    // * some requests may need device info to work properly ...
    this.fetchDeviceInfo()
      .then(() => console.info('Success fetching device info!'))
      .catch((res) => console.warn('Error getting device info:', res));

    this.avisoPushDuvida();

    setInterval(() => this.updateUserInfo(), 15000);
  }

  async fetchDeviceInfo() {
    this.imei = await this.util.getDeviceID();
    this.plataforma = await this.util.getDevicePlatform();
    this.aparelho = await this.util.getDeviceModel();
  }

  requestNotificationPermission() {
    if (!('Notification' in window)) {
      this.util.showToast(
        'Essa plataforma parece não ter compatibilidade com notificações ...',
      );
      return;
    }

    Notification.requestPermission()
      .then((permission) => {
        if (permission === 'granted') {
          localStorage.setItem('pushNotifications', 'true');
        }

        if (permission === 'denied') {
          localStorage.setItem('pushNotifications', 'false');
          this.util.showToast(
            'Você não receberá notificações de rastreamento.',
          );
        }
      })
      .catch((error) => {
        console.error('Erro ao solicitar permissão de notificações:', error);
      });
  }

  verifyNotificationPermission() {
    if (!('Notification' in window)) {
      console.log('Este navegador não suporta notificações.');
      return;
    }

    const permission = Notification.permission;

    if (permission === 'granted') {
      localStorage.setItem('pushNotifications', 'true');
    }

    if (permission === 'denied') {
      localStorage.setItem('pushNotifications', 'false');
    }

    if (permission === 'default') {
      console.warn('User dos not have granted notification choice...');
    }
  }

  // * case in PWA/Web
  oneSignalWeb() {
    try {
      if (!localStorage.getItem('chave_push')) {
        const chaveAleatoria = this.gerarChaveAleatoria(20);
        localStorage.setItem('chave_push', chaveAleatoria);
      }

      const externalId = localStorage.getItem('chave_push')

      this.oneSignal
        .init({
          appId: environment.chaveOneSignal,
          serviceWorkerParam: { scope: '/' },
          serviceWorkerPath: '/OneSignalSDKWorker.js',
          allowLocalhostAsSecureOrigin: true,
        })
        .then(() => {
          this.oneSignal.login(externalId);
        })
        .catch((error) => {
          console.error(error);
        });

      localStorage.setItem('pushNotifications', 'true');

      if (`${localStorage.getItem('hash')}`.split('').length > 6) {
        this.util.showToast('Este aplicativo está recebendo pushs!');
      }

      const request = {
        token: localStorage.getItem('hash'),
        player: localStorage.getItem('chave_push'),
        imei: this.imei,
        aparelho: this.aparelho,
        plataforma: this.plataforma,
      };

      console.log('request oneSignalWeb', request);

      this.usuario
        .atualizarPush(request)
        .then((res) => {
          console.info('Att. push', res);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error('Erro ao configurar OneSignal:', error);
    }
  }

  // * case in android/ios
  oneSignalInit() {
    const chaveAleatoria = this.gerarChaveAleatoria(20);
    localStorage.setItem('chave_push', chaveAleatoria);

    try {
      window['plugins'].OneSignal.setAppId(environment.chaveOneSignal);
      console.log(window['plugins'].OneSignal);
      window['plugins'].OneSignal.setNotificationOpenedHandler((jsonData) => {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      });
      setTimeout(() => {
        window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse(
          (accepted) => {
            if (!accepted) {
              localStorage.setItem('pushNotifications', 'false');
              if (`${localStorage.getItem('hash')}`.split('').length > 6) {
                this.avisoPushNegativo();
              }
            } else {
              localStorage.setItem('pushNotifications', 'true');
              if (`${localStorage.getItem('hash')}`.split('').length > 6) {
                this.util.showToast('Este aplicativo está recebendo pushs!');
              }
            }
            console.log('User accepted notifications: ' + accepted);
          },
        );
      }, 1000);
      window['plugins'].OneSignal.setExternalUserId(chaveAleatoria);
    } catch (error) {
      console.log('erro -> ', error);
    }

    const request = {
      token: localStorage.getItem('hash'),
      player: chaveAleatoria,
      imei: this.imei,
      aparelho: this.aparelho,
      plataforma: this.plataforma,
    };

    this.usuario
      .atualizarPush(request)
      .then((res) => console.info('Success updating push:', res))
      .catch((error) => console.warn('Erro while updating push', error));
  }

  // * some this are confusing, may need refactor ...
  updateUserInfo() {
    console.info('Updating user info ...');
    let request = { token: localStorage.getItem('hash') };
    if (localStorage.getItem('url_padrao') != null) {
      if (localStorage.getItem('url_padrao')) {
        this.user.getMenu(request).then((response) => {
          const res = JSON.parse(JSON.stringify(response));
          if (res.success === true) {
            localStorage.setItem(
              'exibe_parceiros',
              res.dados_empresa.exibe_parceiros,
            );
            localStorage.setItem(
              'bloqueio_msg',
              res.dados_empresa.mensagem_bloqueio,
            );
            localStorage.setItem(
              'dados_cliente',
              JSON.stringify(res.dados_cliente),
            );
            localStorage.setItem(
              'situacao_sga',
              res.dados_cliente.situacao_sga,
            );
            localStorage.setItem('tem_rotas', res.dados_cliente.tem_rotas);
            localStorage.setItem(
              'dados_empresa',
              JSON.stringify(res.dados_empresa),
            );
            localStorage.setItem(
              'dns_empresa',
              res.dados_empresa.dados_login.dns,
            );
            localStorage.setItem('color', '#b134eb');
            let cor = '#' + res.dados_empresa.cor_app;
            localStorage.setItem('atendimento', res.dados_empresa.atendimento);
            localStorage.setItem(
              'whatsapp_telefone',
              res.dados_empresa.whatsapp_telefone,
            );
            localStorage.setItem(
              'whatsapp_mensagem',
              res.dados_empresa.whatsapp_mensagem,
            );
            localStorage.setItem(
              'link_personalizado',
              res.dados_empresa.link_personalizado,
            );
            localStorage.setItem(
              'mensagem_personalizado',
              res.dados_empresa.mensagem_personalizado,
            );
            localStorage.setItem('dashapp', res.dados_empresa.dashapp);
            localStorage.setItem('cor', cor);
            localStorage.setItem('id_admin', res.dados_cliente.id_admin);
            this.setCor();
            localStorage.setItem('logomarca', res.dados_empresa.logo2);
            localStorage.setItem('favicon', res.dados_empresa.logo3);
            localStorage.setItem(
              'exibir_contratos_app',
              res.dados_empresa.exibir_contratos_app,
            );
            localStorage.setItem(
              'exibe_arquivo',
              res.dados_empresa.exibe_arquivo,
            );
            localStorage.setItem(
              'exibe_contatos',
              res.dados_empresa.exibe_contatos,
            );

            localStorage.setItem('exibe_chat', res.dados_empresa.exibe_chat);
            localStorage.setItem(
              'exibe_indique_um_amigo',
              res.dados_empresa.exibe_indique_um_amigo,
            );
            localStorage.setItem(
              'exibe_sobre_empresa',
              res.dados_empresa.exibe_sobre_empresa,
            );
            if (
              res.dados_cliente.tipo === 'A1' ||
              res.dados_cliente.tipo === 'A2' ||
              res.dados_cliente.tipo === 'A3'
            ) {
              localStorage.setItem('exibe_parametros', '1');
            } else {
              localStorage.setItem(
                'exibe_parametros',
                res.dados_cliente.exibe_parametros,
              );
            }
            localStorage.setItem(
              'numero_atendimento',
              res.dados_empresa.numero_atendimento,
            );
            localStorage.setItem(
              'exibe_faturamento',
              res.dados_empresa.exibe_faturamento,
            );
            localStorage.setItem(
              'exibir_termo_app',
              res.dados_empresa.exibir_termo_app,
            );
            localStorage.setItem(
              'menuinicial_app',
              res.dados_empresa.menuinicial_app,
            );
            localStorage.setItem(
              'permitir_obs_app',
              res.dados_empresa.permitir_obs_app,
            );
            localStorage.setItem(
              'exibir_saude',
              res.dados_empresa.exibir_saude,
            );
            localStorage.setItem(
              'exibir_eventos',
              res.dados_empresa.exibir_eventos,
            );
            localStorage.setItem(
              'mostrar_boletos',
              res.dados_empresa.mostrar_boletos,
            );
            localStorage.setItem(
              'selfie_ass_contrato',
              res.dados_empresa.selfie_ass_contrato,
            );
            localStorage.setItem(
              'termos_pre_acesso',
              res.dados_empresa.termos_pre_acesso,
            );
            localStorage.setItem(
              'codigo_associado',
              res.dados_cliente.codigos_associado,
            );
            localStorage.setItem(
              'sistema_pontos',
              res.dados_empresa.sistema_de_pontos ? '1' : '0',
            );
            localStorage.setItem(
              'bg_cartao_v2_frente',
              res.dados_empresa.bg_cartao_v2_frente,
            );
            localStorage.setItem(
              'bg_cartao_v2_verso',
              res.dados_empresa.bg_cartao_v2_verso,
            );
            localStorage.setItem(
              'cartao_associado_v2',
              res.dados_empresa.cartao_associado_v2,
            );

            localStorage.setItem(
              'mostrar_clube_gas',
              res.dados_empresa.mostrar_clube_gas,
            );
            localStorage.setItem(
              'url_clube_gas',
              res.dados_empresa.url_clube_gas,
            );
            localStorage.setItem(
              'mostrar_odonto_clube',
              res.dados_empresa.mostrar_odonto_clube,
            );
            localStorage.setItem(
              'url_odonto_clube',
              res.dados_empresa.url_odonto_clube,
            );
            localStorage.setItem(
              'mostrar_doc24',
              res.dados_empresa.mostrar_doc24,
            );
            localStorage.setItem('url_doc24', res.dados_empresa.url_doc24);
            localStorage.setItem(
              'url_eventos_sga',
              res.dados_empresa.url_eventos_sga,
            );
            localStorage.setItem(
              'mostrar_posto_bufon',
              res.dados_empresa.mostrar_posto_bufon,
            );
            localStorage.setItem(
              'url_posto_bufon',
              res.dados_empresa.url_posto_bufon,
            );
            localStorage.setItem(
              'nome_topo_app',
              res.dados_empresa.nome_topo_app,
            );

            localStorage.setItem('app_atualizado', 'true');
            localStorage.setItem('app_url', '');
            console.log(res.dados_empresa.dados_login);
            var lg = res.dados_empresa.dados_login;
            var versao = '';
            var url = '';
            if (
              this.plataforma === 'Android' ||
              this.plataforma === 'android'
            ) {
              versao = lg ? lg['versao_android'] : '';
              url = lg ? lg['url_android'] : '';
            } else {
              versao = lg ? lg['versao_ios'] : '';
              url = lg ? lg['url_ios'] : '';
            }
            if (versao) {
              if (versao.includes('.')) {
                console.log(versao);
                var teste1 = versaoApp.split('.');
                var teste2 = versao.split('.');
                if (versao !== versaoApp) {
                  if (teste1.length > 2) {
                    var a = parseInt(teste1[0]);
                    var b = parseInt(teste1[1]);
                    var c = parseInt(teste1[2]);

                    var a2 = parseInt(teste2[0]);
                    var b2 = parseInt(teste2[1]);
                    var c2 = parseInt(teste2[2]);

                    var atualizado = false;

                    if (a > a2) {
                      atualizado = true;
                    } else {
                      if (b > b2) {
                        atualizado = true;
                      } else {
                        if (c > c2) {
                          atualizado = true;
                        }
                      }
                    }
                    console.log('seta');
                    console.log(atualizado ? 'updated' : 'not updated');
                    localStorage.setItem('app_atualizado', '' + atualizado);
                    localStorage.setItem('app_url', url);
                    localStorage.setItem('app_versao', versao);
                  }
                }
              }
            }
          }
        });
      }
    }
  }

  setCor() {
    const mainbg = localStorage.getItem('cor') || '#326d8c';

    setCssColors(mainbg);
  }

  gerarChaveAleatoria(tamanho: number) {
    const caracteres =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let chave = '';
    for (let i = 0; i < tamanho; i++) {
      const indice = Math.floor(Math.random() * caracteres.length);
      chave += caracteres.charAt(indice);
    }
    return chave;
  }

  async avisoPushNegativo() {
    const alert = await this.alertCtrl.create({
      header:
        'Suas notificações push estão desativadas, por favor abra as configurações do aplicativo, e ative-as.',
      buttons: [
        {
          text: 'OK',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  async avisoPushDuvida(resetUserChoice?: boolean) {
    const alert = await this.alertCtrl.create({
      header: 'Gostaria de receber notificações push?',
      buttons: [
        {
          text: 'SIM',
          handler: () => {
            if (this.plataforma === 'web') {
              this.oneSignalWeb();
            }

            if (this.plataforma !== 'web') {
              this.oneSignalInit();
            }
          },
        },
        {
          text: 'Talvez mais tarde',
          handler: () => {
            localStorage.setItem('pushNotifications', 'false');

            if (localStorage.getItem('chave_push')) {
              if ('serviceWorker' in navigator) {
                this.oneSignal.logout();
                window.location.reload()
              }
            }
          },
        },
      ],
    });

    // * show modal in only first login
    if (
      localStorage.getItem('hash') &&
      !localStorage.getItem('pushNotifications')
    ) {
      await alert.present();
    }

    // * if user wants to change the notification choice
    if (resetUserChoice) {
      await alert.present();
    }
  }
}
