import { Events } from './Events';
import { UtilService } from './util-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class SgaService {
  constructor(public http: HttpClient, public util: UtilService,public events: Events,) { }

  login(request: any){

    const host = 'https://api.hinova.com.br/api/sga/v2' + '/usuario/autenticar';

    const params = request;

    const options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Authorization', 'Bearer '+request.sga_authorization)
    };

    //headers.append('Content-Type', 'application/json');
    return this.http.post(host, params, {headers: options.headers});
  }

  getAtendimentos(request: any){
    const host = 'https://api.hinova.com.br/api/sga/v2' + '/buscar/historico-atendimento-associado/' + request.cpf;

    const options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Authorization', 'Bearer '+request.bearer_token)
    };

    return this.http.get(host, {headers:options.headers});

  }

  getProdutos(request: any){
    const host = 'https://api.hinova.com.br/api/sga/v2' + '/produto-vinculado-veiculo/listar/' + request.placa;

    const options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Authorization', 'Bearer '+request.bearer_token)
    };

    return this.http.get(host, {headers:options.headers});
  };

  getAssociado(request: any){

    const host = 'https://api.hinova.com.br/api/sga/v2' + '/associado/buscar/'+request.cpf;

    console.log(request.bearer_token);
    //const params = JSON.stringify(request)
    const options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Authorization', 'Bearer '+request.bearer_token)
    };
    //headers.append('Content-Type', 'application/json');
    return this.http.get(host, {headers:options.headers});
  }

  getBoletos(request: any){

    const host = 'https://api.hinova.com.br/api/sga/v2'+ '/listar/boleto-associado-veiculo';
    const params = {
      codigoAssociado: request.codigo_associado,
      dataVencimentoInicial: request.data_vencimento_inicial,
      dataVencimentoFinal: request.data_vencimento_final
    };
    const options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Authorization', 'Bearer '+request.bearer_token)
    };
    console.log('Boletos');
    console.log(params);
    console.log(options.headers);
    console.log('-----');

  //headers.append('Content-Type', 'application/json');
    return this.http.post(host, params, {headers:options.headers});
  }

  getBoleto(request: any){
    const host = 'https://api.hinova.com.br/api/sga/v2'+ '/buscar/boleto/' + request.nosso_numero;

    const options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Authorization', 'Bearer '+request.bearer_token)
    };
  //headers.append('Content-Type', 'application/json');

    return this.http.get(host, {headers:options.headers});

  }

  getEventos(request: any){
    const host = 'https://api.hinova.com.br/api/sga/v2/listar/evento-veiculo/'+request.placa;
    console.log('Realizando: '+host);
    const options = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Authorization', 'Bearer '+request.bearer_token)
    };
    return this.http.get(host, {headers:options.headers});

  }}
