import { Component, AfterViewInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilService } from 'src/providers/util-service';
import { PhotoService } from '../photo.service';
import { ShowPictureComponent } from '../show-picture/show-picture.component';
@Component({
    selector: 'app-camera-modal',
    templateUrl: './camera-modal.component.html',
    styleUrls: ['./camera-modal.component.scss'],
})
export class CameraModalComponent implements AfterViewInit {
    usingRearCamera = true;
    private stream: MediaStream;

    constructor(
        private modalCtrl: ModalController,
        private utils: UtilService,
        private photoService: PhotoService,
    ) {}

    ngAfterViewInit() {
        this.startCamera();
    }

    ngOnDestroy() {
        this.stopCamera();
    }

    stopCamera() {
        if (this.stream) {
            this.stream.getTracks().forEach((track) => track.stop());
        }
    }

    startCamera() {
        if (
            'mediaDevices' in navigator &&
            'getUserMedia' in navigator.mediaDevices
        ) {
            const video = document.querySelector('#video') as HTMLVideoElement;

            // ? Futuramente se solicitado, implementar função
            // ? de troca de camera ...

            const constraints = {
                video: {
                    facingMode: this.usingRearCamera ? 'environment' : 'user',
                },
            };

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    this.stream = stream;
                    video.srcObject = stream;
                    video.style.width = '100%';
                    video.play;
                })
                .catch((error) => {
                    console.error('Erro ao acessar a câmera:', error);
                    this.utils.showToast('Não foi possível acessar sua câmera');
                });
        } else {
            this.utils.showToast('Houve um erro de incompatibilidade... ');
        }
    }

    // ? implementação futura ...
    toggleCamera() {
        this.usingRearCamera = !this.usingRearCamera;
        this.startCamera();
    }

    capturePhoto() {
        const video = document.querySelector('#video') as HTMLVideoElement;
        const canvas = document.querySelector('#canvas') as HTMLCanvasElement;

        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;

        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0);

        const photo = canvas.toDataURL('image/png');
        this.photoService.setPhoto(photo);
        this.showPictureModal();
        this.modalCtrl.dismiss();
    }

    onFileSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
            const file = input.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const photo = reader.result as string;
                this.photoService.setPhoto(photo);
                this.showPictureModal();

                this.modalCtrl.dismiss();
            };

            reader.readAsDataURL(file);
        }
    }

    async showPictureModal() {
        const modal = await this.modalCtrl.create({
            component: ShowPictureComponent,
        });

        await modal.present();
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }
}
