import { UtilService } from './util-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { timeout } from 'rxjs/operators';
import { HttpResponse } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class VeiculoService {
  constructor(public http: HttpClient, public util: UtilService) {}

  //carros do cliente
  getVeiculos(request: any): Promise<HttpResponse> {
    //let host = this.util.obterHostDaApi() + 'dashboard';
    const uri = 'dashboard8';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "client_id": "' +
      request.clientId +
      '", "vehicle_id": "" ,"placa": "' +
      request.placa +
      '","offset": "' +
      request.offset +
      '","ignicao": "' +
      request.ignicao +
      '"  }';

    //headers.append('Content-Type', 'application/json');
    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
    /*
    host = host + '?token=' + request.token + "&client_id=" + request.client_id + "&vehicle_id=";
    */
  }

  validaDash(request: any): Promise<HttpResponse> {
    //let host = this.util.obterHostDaApi() + 'dashboard';
    const uri = 'dashboard_valid';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + '{"token": "' + request.token + '" }';

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
    /*
    host = host + '?token=' + request.token + "&client_id=" + request.client_id + "&vehicle_id=";
    */
  }

  getHashRapido(request: any): Promise<HttpResponse> {
    //let host = this.util.obterHostDaApi() + 'dashboard';
    const uri = 'get_hash';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "imei": "' +
      request.imei +
      '", "duracao": "' +
      request.duracao +
      '"}';

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
    /*
    host = host + '?token=' + request.token + "&client_id=" + request.client_id + "&vehicle_id=";
    */
  }

  getVeiculosTodos(request: any): Promise<HttpResponse> {
    //let host = this.util.obterHostDaApi() + 'dashboard';
    const uri = 'dashboard8';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "client_id": "' +
      request.clientId +
      '", "vehicle_id": "" ,"placa": "' +
      request.placa +
      '","offset": "' +
      request.offset +
      '", "limite": "9999"}';

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
    /*
    host = host + '?token=' + request.token + "&client_id=" + request.client_id + "&vehicle_id=";
    */
  }

  getAlertas(request: any): Promise<HttpResponse> {
    const uri = 'alertas';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados={' +
      '"token": "' +
      request.token +
      '", "imei":"' +
      request.imei +
      '"}';

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getCercas(request: any): Promise<HttpResponse> {
    const uri = 'cercas';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados={' +
      '"token": "' +
      request.token +
      '", "imei":"' +
      request.imei +
      '"}';

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  //carros do cliente
  getVeiculosUnico(request: any): Promise<HttpResponse> {
    const uri = 'dashboard8';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "client_id": "' +
      request.client_id +
      '", "vehicle_id": "' +
      request.vehicle_id +
      '" }';
    //let host = this.util.obterHostDaApi() + '/dashboard';
    //host = host + '?token=' + request.token + "&client_id=" + request.client_id + "&vehicle_id=" + request.vehicle_id;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http
      .get<HttpResponse>(host, options)
      .toPromise()
      .catch((err) => {
        throw new Error(err);
      });
  }

  setHodometro(request: any): Promise<HttpResponse> {
    const uri = 'hodometro';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "imei": "' +
      request.imei +
      '", "valor": "' +
      request.valor +
      '"}';
    //let host = this.util.obterHostDaApi() + 'altera_ancora';
    //host = host + '?token=' + request.token + "&imei=" + request.imei;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  setVelocidade(request: any): Promise<HttpResponse> {
    const uri = 'change_velocidade';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "imei": "' +
      request.imei +
      '", "velocidade": "' +
      request.velocidade +
      '"}';

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  setIgnicao(request: any): Promise<HttpResponse> {
    const uri = 'altera_ancora';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "imei": "' +
      request.imei +
      '"}';
    //let host = this.util.obterHostDaApi() + 'altera_ancora';
    //host = host + '?token=' + request.token + "&imei=" + request.imei;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  setIgnicaoAlerta(request: any): Promise<HttpResponse> {
    const uri = 'altera_ignicao';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "imei": "' +
      request.imei +
      '"}';
    //let host = this.util.obterHostDaApi() + 'altera_ignicao';
    //host = host + '?token=' + request.token + "&imei=" + request.imei;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  setBloqueio(request: any): Promise<HttpResponse> {
    const uri = 'bloqueio';
    const host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    //const host = this.util.obterHostDaApi() + 'bloqueio';

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  async getTipoVeiculo(getTiporequest: any): Promise<HttpResponse> {
    const uri = 'listas/tipos';
    const host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    //host = host + "&"+"dados="+'{"token": "'+ request.token + '", "id_rastreador": "'+ request.id_rastreador+'"}'
    //let host = this.util.obterHostDaApi() + 'listas/tipos';
    //host = host + '?token=' + request.token + "&imei=" + request.imei;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return await this.http.get<HttpResponse>(host, options).toPromise();
  }

  getRastreador(request: any): Promise<HttpResponse> {
    const uri = 'listas/rastreadores';
    const host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    //host = host + "&"+"dados="+'{"token": "'+ request.token + '", "id_rastreador": "'+ request.id_rastreador+'"}'
    //let host = this.util.obterHostDaApi() + 'listas/rastreadores';
    //host = host + '?token=' + request.token + "&imei=" + request.imei;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getOperadora(request: any): Promise<HttpResponse> {
    const uri = 'listas/operadoras';
    const host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    //host = host + "&"+"dados="+'{"token": "'+ request.token + '", "id_rastreador": "'+ request.id_rastreador+'"}'
    //let host = this.util.obterHostDaApi() + 'listas/operadoras';
    //host = host + '?token=' + request.token + "&imei=" + request.imei;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  salvarParametros(request: any): Promise<HttpResponse> {
    const uri = 'parametros';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&method=POST';
    //let host = this.util.obterHostDaApi() + 'parametros';
    let bateria = 0;
    if (request.bateria_removida) {
      bateria = 1;
    }

    let gsm = 0;
    if (request.desconexao) {
      gsm = 1;
    }
    let altmovimentacao = 0;
    if (request.altmovimentacao) {
      altmovimentacao = 1;
    }
    const altoff = request.altoff;
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "imei": "' +
      request.imei +
      '", "velocidade": "' +
      request.velocidade +
      '", "altoff":"' +
      altoff +
      ' ","bateria_removida": "' +
      bateria +
      '", "desconexao":"' +
      gsm +
      '", "altmovimentacao":"' +
      altmovimentacao +
      '", "altoff": "' +
      altoff +
      '"}';

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  /*
  comandos(request: any): Promise<HttpResponse> {
    let host = this.util.obterHostDaApi() + 'rastreamento/valida';
    host = host + '?token=' + request.token + "&id_rastreador="+ request.id_rastreador;
    let headers = new Headers(
      {
        'Content-Type': 'application/x-www-form-urlencoded'
      });
    let options = new RequestOptions({ headers: headers });
    return  this.http.post(host, options).toPromise();
  }*/

  listaComandos(request: any): Promise<HttpResponse> {
    const uri = 'listas/comandos';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "id_rastreador": "' +
      request.idRastreador +
      '"}';

    //let host = this.util.obterHostDaApi() + 'listas/comandos';
    //host = host + '?token=' + request.token + "&id_rastreador="+ request.id_rastreador;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  executarComando(request: any): Promise<HttpResponse> {
    const uri = 'envia_comando/' + request.handler;
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&' +
      'method=POST';
    let comando = request.command;
    comando = comando.split(';').join('%3B');
    comando = comando.replace('{{imei}}', request.imei);
    host =
      host +
      '&' +
      'dados=' +
      '{"token":"' +
      request.token +
      '","imei":"' +
      request.imei +
      '","command":"' +
      comando +
      '"}';
    host = host.split('#').join('%23');
    //let host = this.util.obterHostDaApi() + 'envia_comando/';
    //host = host + request.handler + '?token=' + request.token + "&imei="+ request.imei + "&command="+ request.command;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  podeRastrear(request: any): Promise<HttpResponse> {
    const uri = 'rastreamento/valida';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "imei": "' +
      request.imei +
      '"}';

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  novoVeiculo(request: any): Promise<HttpResponse> {
    const uri = 'add_bem';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&' +
      'method=POST';
    //let host = this.util.obterHostDaApi() + 'add_bem';
    if (request.id != null) {
      host =
        host +
        '&' +
        'dados=' +
        '{"token": "' +
        request.token +
        '", "imei": "' +
        request.imei +
        '" , "nome": "' +
        request.nome +
        '", "placa": "' +
        request.placa +
        '" , "id_rastreador": "' +
        request.id_rastreador +
        '", "chip": "' +
        request.chip.replace('.', '').replace('-', '').replace(' ', '') +
        '", "identificacao": "' +
        request.identificacao +
        '", "id_operadora": "' +
        request.id_operadora +
        '", "id_tipo": "' +
        request.id_tipo +
        '", "id": "' +
        request.id +
        '"}';
    } else {
      host =
        host +
        '&' +
        'dados=' +
        '{"token": "' +
        request.token +
        '", "imei": "' +
        request.imei +
        '" , "nome": "' +
        request.nome +
        '", "placa": "' +
        request.placa +
        '" , "id_rastreador": "' +
        request.id_rastreador +
        '", "chip": "' +
        request.chip.replace('.', '').replace('-', '').replace(' ', '') +
        '", "identificacao": "' +
        request.identificacao +
        '", "id_operadora": "' +
        request.id_operadora +
        '", "id_tipo": "' +
        request.id_tipo +
        '"}';
    }

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  excluiVeiculo(request: any): Promise<HttpResponse> {
    const uri = 'delete_bem';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '') +
      '&' +
      'method=POST';
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "imei": "' +
      request.imei +
      '" }';
    //let host = this.util.obterHostDaApi() + 'delete_bem';
    //host = host + '?token=' + request.token + "&imei="+ request.imei;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  buscaPosicao(request: any): Promise<HttpResponse> {
    const uri = 'grid_recent';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/apis_aplicativo_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"token": "' +
      request.token +
      '", "imei": "' +
      request.imei +
      '" }';
    //let host = this.util.obterHostDaApi() + 'grid_recent';
    //host = host + '?token=' + request.token + "&imei="+ request.imei;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  buscaUltimas(request: any): Promise<HttpResponse> {
    const uri = 'hapolo/last-ten';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/microservices_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host = host + '&' + 'dados=' + `{"imei": "${request.imei}" }&timeout=15`;
    //let host = this.util.obterHostDaApi() + 'grid_recent';
    //host = host + '?token=' + request.token + "&imei="+ request.imei;

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  relatorioVeiculo(request: any): Promise<HttpResponse> {
    const uri = 'app/logs-v3';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/logs_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      this.util.obterHostDaApi().replace(':8890', '');
    host =
      host +
      '&' +
      'dados=' +
      '{"imei": "' +
      request.imei +
      '", "data_ini": "' +
      request.dataIni +
      '", "data_fim":"' +
      request.dataFim +
      '", "agrupado": "' +
      request.agrupado +
      '" }';
    //let host = this.util.obterHostDaApi().replace("8890","8888") + 'app/logs-v3';
    //host = host + '?imei=' + request.imei + "&data_ini="+ request.data_ini+ "&data_fim="+ request.data_fim;
    //host = host + '?imei=1345512&data_ini=20180705000000&data_fim=20180714000000';

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  getEndereco(request: any): Promise<HttpResponse> {
    const uri = '';
    let host =
      this.util.obterHostPadraoDaApi() +
      '/api/endereco_ponte' +
      '?' +
      'uri=' +
      uri +
      '&' +
      'ip=' +
      '';
    host =
      host +
      '&' +
      'dados=' +
      '{"lat": "' +
      request.lat +
      '", "lon": "' +
      request.lon +
      '", "format": "json" }';

    //let host = "http://address.dipsystem.com.br/nominatim/reverse";
    //host = host + '?lat=' + request.lat + "&lon="+ request.lon+ "&format=json";

    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
    };

    return this.http.get<HttpResponse>(host, options).toPromise();
  }

  alterarStatusMotorista(request: any) {
    // requisição remota (falta testar 09/2022)
    const uri = 'alterar-status-motorista';
    const host = `${this.util.obterHostPadraoDaApi()}/api/apis_aplicativo_ponte?uri=${uri}&ip=${this.util
      .obterHostDaApi()
      .replace(':8890', '')}&dados=${JSON.stringify(request)}&method=POST`;

    const headers = new Headers({
      contentType: 'application/x-www-form-urlencoded;',
    });
    const options = {
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/x-www-form-urlencoded;'
      ),
      timeout: 5000,
    };

    return this.http.get(host, options).toPromise();
  }
}
