import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PhotoService {
    private photoSource = new BehaviorSubject<string | null>(null);
    private confirmedPhotoSource = new BehaviorSubject<string | null>(null);

    currentPhoto = this.photoSource.asObservable();
    confirmedPhoto = this.confirmedPhotoSource.asObservable();

    constructor() {}

    convertToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    }

    setPhoto(photo: string) {
        this.photoSource.next(photo);
    }

    confirmPhoto() {
        const currentPhoto = this.photoSource.value;
        if (currentPhoto) {
            this.confirmedPhotoSource.next(currentPhoto);
        }
    }

    clearPhotos() {
        this.photoSource.next(null);
        this.confirmedPhotoSource.next(null);
    }

    async getPhotoBase64(file: File): Promise<string> {
        return await this.convertToBase64(file).then((base64) => {
            this.setPhoto(base64);
            return base64;
        });
    }
}
