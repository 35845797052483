import { Component, OnInit } from '@angular/core';
import { PhotoService } from '../photo.service';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-show-picture',
    templateUrl: './show-picture.component.html',
    styleUrls: ['./show-picture.component.scss'],
})
export class ShowPictureComponent implements OnInit {
    photo: string | null = null;

    constructor(
        private photoService: PhotoService,
        private modalCtrl: ModalController,
    ) {}

    ngOnInit(): void {
        this.photoService.currentPhoto.subscribe((photo) => {
            this.photo = photo;
        });
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

    confirmPhoto() {
        this.photoService.confirmPhoto();
        this.dismiss();
    }
}
