const colorLuminance = (hex, lum) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;
  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
};
const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const setCssColors = (cor) => {
  const el = document.querySelector('html');
  el.style.setProperty('--color-primary', colorLuminance(cor, -0.3));
  // el.style.setProperty('--color-text', colorLuminance(cor, -0.5));
  el.style.setProperty('--color-text', '#333333');
  el.style.setProperty('--color-text-rgb', hexToRgb('#333333').join(','));

  el.style.setProperty('--color-bg', cor);
  el.style.setProperty('--color-bg-shade-1', colorLuminance(cor, -0.1));
  el.style.setProperty(
    '--color-bg-shade-1-rgb',
    hexToRgb(colorLuminance(cor, -0.1)).join(', ')
  );
  el.style.setProperty('--color-bg-shade-2', colorLuminance(cor, -0.3));
  el.style.setProperty(
    '--color-bg-shade-2-rgb',
    hexToRgb(colorLuminance(cor, -0.3)).join(', ')
  );
  el.style.setProperty('--color-bg-shade-3', colorLuminance(cor, -0.5));
  el.style.setProperty(
    '--color-bg-shade-3-rgb',
    hexToRgb(colorLuminance(cor, -0.5)).join(', ')
  );
  el.style.setProperty('--color-bg-shade-4', colorLuminance(cor, -0.7));
  el.style.setProperty(
    '--color-bg-shade-4-rgb',
    hexToRgb(colorLuminance(cor, -0.7)).join(', ')
  );
  el.style.setProperty('--color-bg-shade-5', colorLuminance(cor, -0.9));
  el.style.setProperty(
    '--color-bg-shade-5-rgb',
    hexToRgb(colorLuminance(cor, -0.9)).join(', ')
  );
  el.style.setProperty('--color-bg-tint-1', colorLuminance(cor, 0.1));
  el.style.setProperty(
    '--color-bg-tint-1-rgb',
    hexToRgb(colorLuminance(cor, 0.1)).join(', ')
  );
  el.style.setProperty('--color-bg-tint-2', colorLuminance(cor, 0.3));
  el.style.setProperty(
    '--color-bg-tint-2-rgb',
    hexToRgb(colorLuminance(cor, 0.3)).join(', ')
  );
  el.style.setProperty('--color-bg-tint-3', colorLuminance(cor, 0.5));
  el.style.setProperty(
    '--color-bg-tint-3-rgb',
    hexToRgb(colorLuminance(cor, 0.5)).join(', ')
  );
  el.style.setProperty('--color-bg-tint-4', colorLuminance(cor, 0.7));
  el.style.setProperty(
    '--color-bg-tint-4-rgb',
    hexToRgb(colorLuminance(cor, 0.7)).join(', ')
  );
  el.style.setProperty('--color-bg-tint-5', colorLuminance(cor, 0.9));
  el.style.setProperty(
    '--color-bg-tint-5-rgb',
    hexToRgb(colorLuminance(cor, 0.9)).join(', ')
  );
}
